import * as React from "react";
import Layout from "../components/layout";
import langList from '../../data/languages.json';
import LangsBox from "../components/LangsBox";
import PostsComponent from "../components/PostsComponent";
import DayComponent from "../components/DayComponent";
import MonthComponent from "../components/MonthComponent";

const monthInText_arr = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const Index = ({ pageContext }) => {
  const monthTitle = `${pageContext.kernel_name} in ${pageContext.yearDate}`;
  const dayTitle = `${pageContext.kernel_name} for ${monthInText_arr[pageContext.monthDate-1]} ${pageContext.yearDate}`;
  const postsTitle = `${pageContext.kernel_name} on ${monthInText_arr[pageContext.monthDate-1]} ${pageContext.dayDate}, ${pageContext.yearDate}`;
  return (
    <Layout menuList={[]} pageTitle={pageContext.kernel_name} pageLang={pageContext.lang}>
      <div className={'col-sm-12'}>
        <h1 style={{marginTop: '30px'}}>{pageContext.kernel_name}</h1>
      </div>
      <div className={'container'}>
        <div className={'row'}>
            {langList.map(elem => {
            return <LangsBox key={Math.random()} title={elem.region} links={elem.langs}/>
            })}
        </div>
      </div>
      <div style={{marginTop: '10px', marginBottom: '5px'}}>
        <PostsComponent
            postsContent={pageContext.content.posts}
            pageTitle={postsTitle}
            language={pageContext.lang.name}
            yearDate={pageContext.yearDate}
            monthDate={pageContext.monthDate}
            dayDate={pageContext.dayDate}/>
      </div>
      <div style={{marginTop: '5px', marginBottom: '5px'}}>
      <DayComponent dayContext={pageContext.content.day}
            pageTitle={dayTitle}
            language={pageContext.lang.name}
            yearDate={pageContext.yearDate}
            monthDate={pageContext.monthDate} />
      </div>
      <div style={{marginTop: '5px', marginBottom: '5px'}}>
      <MonthComponent 
            monthContent={pageContext.content.month}
            pageTitle={monthTitle}
            language={pageContext.lang.name}
            yearDate={pageContext.yearDate}/>
      </div>
    </Layout>
    )
}

export default Index;