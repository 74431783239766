import React from 'react';
import { Link } from "gatsby";

const index = {
    paddingTop: '24px'
}

const p_left = {
    paddingLeft: '20px'
}

const colClass = 'langLink';
const LangsBox = ({title, links}) => {
    return (
        <div style={index} className={colClass}>
            <h3>{title}</h3>
            <div style={p_left}>
                {links.map(elem => {
                    let url = elem.name.toLowerCase()
                    if (url.indexOf(' ') >= 0) {
                        url = url.replace(' ', '_');
                    }
                    return <Link key={Date.now()+Math.random()} to={'/'+url+'/'}>{elem.name}</Link>
                })}
            </div>
        </div>
    );
};

export default LangsBox;